import { useLazyQuery } from '@biletiniz/apollo';
import { FLIGHT_SEND_EMAIL_QUERY } from './gql';

export const useFlightSendEmail = () => {
  const [sendEmail, { data, error, loading }] = useLazyQuery(
    FLIGHT_SEND_EMAIL_QUERY,
    {
      fetchPolicy: `network-only`,
    }
  );

  return {
    sendEmail,
    sendEmailDone: data?.flightSendEmail?.isSend,
    sendEmailError: error?.message,
    isLoadingSendEmail: loading,
  };
};
