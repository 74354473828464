import { useLazyQuery } from '@biletiniz/apollo';
import { BUS_SEND_EMAIL_QUERY } from './gql';

export const useBusSendEmail = () => {
  const [sendEmail, { data, error, loading }] = useLazyQuery(
    BUS_SEND_EMAIL_QUERY,
    {
      fetchPolicy: `network-only`,
    }
  );

  return {
    sendEmail,
    sendEmailDone: data?.busSendEmail?.isSend,
    sendEmailError: error?.message,
    isLoadingSendEmail: loading,
  };
};
