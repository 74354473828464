import { useQuery } from '@biletiniz/apollo';
import { FLIGHT_OFFER_PACKAGES_QUERY } from './gql';

export const useFlightOfferPackages = ({ session, flightOfferId }) => {
  const flightOfferPackagesQuery = useQuery(FLIGHT_OFFER_PACKAGES_QUERY, {
    variables: {
      session,
      flightOfferId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    flightOfferPackages: flightOfferPackagesQuery?.data?.flightOfferPackages,
    loadFlightOfferPackagesError: flightOfferPackagesQuery?.error?.message,
    isLoadingFlightOfferPackages: flightOfferPackagesQuery.loading,
    refetch: flightOfferPackagesQuery.refetch,
  };
};
