import { useQuery } from '@biletiniz/apollo';
import { FLIGHT_OFFER_QUERY } from './gql';

export const useFlightOffer = ({ session, flightOfferId }) => {
  const flightOfferQuery = useQuery(FLIGHT_OFFER_QUERY, {
    variables: {
      session,
      flightOfferId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    flightOffer: flightOfferQuery?.data?.flightOffer,
    loadFlightOfferError: flightOfferQuery?.error?.message,
    isLoadingFlightOffer: flightOfferQuery.loading,
  };
};
