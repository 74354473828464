import { useEffect, useState } from '@biletiniz/ui-core';
import { useLazyQuery } from '@biletiniz/apollo';
import { ALL_BUS_OFFERS_QUERY } from './gql';

const getSearchBusOfferVariable = search => {
  return {
    departureDate: new Date(search?.departureDate),
    origin: parseInt(search?.origin?.value),
    destination: parseInt(search?.destination?.value),
  };
};

let session = null;
const getSession = () => {
  return session;
};

export const useBusOffers = search => {
  const [loaded, setLoaded] = useState(false);
  const [loadBusOffers, { data, error, loading, ...rest }] = useLazyQuery(
    ALL_BUS_OFFERS_QUERY,
    {
      fetchPolicy: `network-only`,
    }
  );
  useEffect(() => {
    const busSearchFilter = getSearchBusOfferVariable(search);
    if (
      busSearchFilter.departureDate &&
      busSearchFilter.origin &&
      busSearchFilter.destination
    ) {
      loadBusOffers({
        variables: {
          ...busSearchFilter,
          session: getSession?.(),
        },
      });
    }
  }, [search]);
  useEffect(() => {
    if (data && !loaded) {
      setLoaded(true);
    }
  }, [data]);

  if (data?.allBusStationOffers?.session !== session) {
    session = data?.allBusStationOffers?.session;
  }
  const searchResults = data?.allBusStationOffers?.busOffers;

  return {
    session,
    busOffers: searchResults,
    loadBusOfferError: error?.message,
    isLoadingBusOffer: loading,
    loadedBusOffer: loaded,
    previousBusOffers: rest.previousData,
  };
};
