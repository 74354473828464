import { useState } from '@biletiniz/ui-core';
import { useMutation } from '@biletiniz/apollo';
import { VisaProviderType } from '@biletiniz/shared';
import { VISA_STORE_DATA_MUTATION } from './gql';

const mapToBiletinizPassenger = passenger => ({
  givenName: passenger.firstName,
  surname: passenger.lastName,
  gender: passenger.gender,
  visaType: passenger.visaType.value,
  email: passenger.email,
  telephone: passenger.gsmNumber,
  birthDate: `${passenger.dateOfBirth.year}-${passenger.dateOfBirth.month}-${passenger.dateOfBirth.day}`,
  departureDate: passenger.departureDate,
});

const passengerInitialValues = (visaProviderType, i18n) => ({
  firstName: '',
  lastName: '',
  gender: '',
  visaStartDate: undefined,
  dateOfBirth: {
    day: '',
    month: '',
    year: '',
  },
  visaType:
    visaProviderType === VisaProviderType.DUBAI
      ? {
          label: i18n.t('visa.type.dubai.single30'),
          value: 'single30',
        }
      : { label: i18n.t('visa.type.russia.single15'), value: 'russia' },
  email: '',
  gsmNumber: '',
  departureDate: undefined,
  personalPhoto: undefined,
  passportPhoto: undefined,
});

export const useVisa = (i18n, visaProviderType) => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const [
    visaStoreDataMutation,
    { loading: storeLoading, error: storeError },
  ] = useMutation(VISA_STORE_DATA_MUTATION);

  const applyVisa = async (travellerInfo, visaType) => {
    const formData = new FormData();
    travellerInfo.travellers.map(traveller =>
      formData.append('personalPhoto', traveller.personalPhoto)
    );
    travellerInfo.travellers.map(traveller =>
      formData.append('passportPhoto', traveller.passportPhoto)
    );

    try {
      setUploadingFiles(true);
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_ROOT_URL}/file-upload`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        setUploadingFiles(false);
        throw new Error(i18n.t('errors.errorContactSupport'));
      }

      const jsonResponse = await response.json();
      if (jsonResponse.success) {
        // Files saved successfully and start saving form fields
        visaStoreDataMutation({
          variables: {
            session: jsonResponse.session,
            visaType,
            passengers: travellerInfo.travellers.map(traveller =>
              mapToBiletinizPassenger(traveller)
            ),
            paymentCard: {
              cardNumber: travellerInfo.cardNumber,
              expireYear: travellerInfo.expire.year,
              expireMonth: travellerInfo.expire.month,
              cvc: travellerInfo.cvc,
              cardHolderName: travellerInfo.cardHolderName,
            },
          },
        }).then(({ data }) => {
          const apiRootUrl = process.env.NEXT_PUBLIC_API_ROOT_URL || '';
          setIframeUrl(
            `${apiRootUrl}/visa-payment-checkout/${data.visaStoreData}`
          );
          setUploadingFiles(false);
        });
      } else {
        setUploadingFiles(false);
        throw new Error(i18n.t('errors.errorContactSupport'));
      }
    } catch (error) {
      setUploadingFiles(false);
      throw new Error(i18n.t('errors.errorContactSupport'));
    }
  };

  const formInitialValues = {
    travellers: [passengerInitialValues(visaProviderType, i18n)],
    cardHolderName: '',
    cardNumber: '',
    expire: {
      month: '',
      year: '',
    },
    cvc: '',
    termsOfService: false,
    adultAtLeast: undefined,
  };

  return {
    applyVisa,
    iframeUrl,
    setIframeUrl,
    formInitialValues,
    storeLoading: storeLoading || uploadingFiles,
    storeError: storeError?.message,
  };
};
