import { useQuery } from '@biletiniz/apollo';

import { ALL_BUS_STATIONS_QUERY } from './gql';

export const useBusTypeahead = () => {
  const busStationsQuery = useQuery(ALL_BUS_STATIONS_QUERY, {
    variables: {
      keyword: '',
    },
    skip: true,
    notifyOnNetworkStatusChange: false,
  });

  const getStations = (keyword, callback) => {
    busStationsQuery
      .refetch({ keyword })
      .then(({ data }) => callback?.(data.busTypeaheadSearch));
  };

  return {
    getStations,
  };
};
