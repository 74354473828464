import { useEffect } from '@biletiniz/ui-core';
import { useRouter } from 'next/router';
import { pageview } from '@biletiniz/google-analytics';

export const useGoogleAnalytics = () => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
