import { useEffect, useState } from '@biletiniz/ui-core';
import { useLazyQuery } from '@biletiniz/apollo';
import { ALL_FLIGHT_OFFERS_QUERY } from './gql';

const getSearchFlightOfferVariable = search => {
  return {
    departureDate: search?.departureDate,
    returnDate: search?.returnDate,
    origin: search?.origin?.value,
    originIsCity: search?.origin?.isCity,
    destination: search?.destination?.value,
    destinationIsCity: search?.destination?.isCity,
    adultsCount: search?.cabinClassTravellers?.travellers?.adultsCount,
    childrenCount: search?.cabinClassTravellers?.travellers?.childrenCount,
    infantsCount: search?.cabinClassTravellers?.travellers?.infantsCount,
    cabinClass: search?.cabinClassTravellers?.cabinClass,
    nonStop: false, // TODO get it from ui in the future
  };
};

const getFilterFlightOfferVariable = filter => {
  return {
    stopsNumber: filter.stopsNumber,
    airlines: filter.airlines,
    fareTypes: filter.fareTypes,
    flightNumber: filter.flightNumber,
    transitAirports: filter.transitAirports,
    directions: filter.directions,
  };
};

let session = null;
const getSession = () => {
  return session;
};

export const useFlightOffers = (search, filter) => {
  const [loaded, setLoaded] = useState(false);
  const [loadFlightOffers, { data, error, loading, ...rest }] = useLazyQuery(
    ALL_FLIGHT_OFFERS_QUERY,
    {
      fetchPolicy: `network-only`,
    }
  );

  useEffect(() => {
    const flightSearchFilter = getSearchFlightOfferVariable(search);
    const flightOfferFilter = getFilterFlightOfferVariable(filter);
    if (
      flightSearchFilter.departureDate &&
      flightSearchFilter.origin &&
      flightSearchFilter.destination
    ) {
      loadFlightOffers({
        variables: {
          flightSearchFilter,
          flightOfferFilter,
          session: getSession?.(),
        },
      });
    }
  }, [search, filter]);

  useEffect(() => {
    if ((data || error) && !loaded) {
      setLoaded(true);
    }
  }, [data, error]);

  if (data?.flightSearch?.session !== session) {
    session = data?.flightSearch?.session;
  }

  const searchResults = data?.flightSearch;

  return {
    ...searchResults,
    session,
    loadFlightOfferError: error?.message,
    isLoadingFlightOffer: loading,
    loadedFlightOffer: loaded,
    previousFlightOffers: rest.previousData,
  };
};
