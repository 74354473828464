import { useQuery } from '@biletiniz/apollo';
import { BUS_BOOKING_INFO_QUERY } from './gql';

export const useBusBookingInfo = ({ session }) => {
  const busBookingInfo = useQuery(BUS_BOOKING_INFO_QUERY, {
    variables: {
      session,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    busBookingInfo: busBookingInfo?.data?.busBookingInfo,
    loadBusBookingInfoError: busBookingInfo?.error?.message,
    isLoadingBusBookingInfo: busBookingInfo.loading,
  };
};
